form {
    fieldset {
        margin-bottom: $baseMargin*4;
    }

    input, textarea, button, select {
        font-family: 'Josefin Sans', arial, sans-serif;
        border: 0px solid #ccc;
        border-bottom-width: 1px;
        width: 100%;
        margin: $baseMargin 0;
        padding: $basePaddingHalf;
        font-size: 1rem;
    }

    input:focus, textarea:focus {
        border-color: $baseColor;
        border-bottom-width: 2px;
        outline: 0;
    }

    button, .button {
        width: auto;
        padding: 0.5rem 1rem;
        background: $baseColor;
        color: $baseRevert;
        cursor: pointer;

        &:hover {
            background: $baseDarker;
        }
    }

    .form-status {
        padding: 0.5rem;
        font-size: 1.5rem;
        color: #ffffff;

        P {
            margin: 0;
        }

        &.alert-success {
            background: $baseDarker;
        }
    }
}