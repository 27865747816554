// Colors
$baseColor: #1ec9df;
$baseDarker: #11727e;
$baseRevert: #fff;
$baseRed: #FF0624;
$baseLight: #f5f5f5;

// Widths / Padding / Margin
$maxWidth: 1080px;
$basePadding: 0.5rem;
$basePaddingHalf: 0.25rem;
$baseMargin: 0.5rem;

$lineHeight: 1.5rem;

$mobile: 768px;
$desktop: 1024px;

