header {
    background-color: $baseColor;
    background: url('/img/Farm.JPG');
    background-size: cover;
    background-position-y: 18%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    img {
        max-height: 280px;
        max-width: 100%;
        margin: 1.5rem;
        margin-bottom: 0;
    }
}