nav {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    background: $baseColor;
    color: $baseRevert;
    padding-top: 1rem;

    ul {
        list-style: none;
        margin: 0 auto;
        padding: 0;
        text-align: center;

        li {
            display: inline-block;
            padding: 1rem;

            a {
                text-decoration: none;
                color: $baseRevert;
                &:hover {
                    opacity: 0.8;
                    border-bottom: 2px solid $baseRed;
                }
            }
        }
    }
}