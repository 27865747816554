@import url('https://fonts.googleapis.com/css?family=Josefin+Sans');
@import url('https://fonts.googleapis.com/css?family=Josefin+Slab:400,700');

body {
    font-family: 'Josefin Sans', arial, sans-serif;
    font-size: 16px;
    line-height: $lineHeight;
    margin:0;
    display: flex;
    min-height: 100vh;
    flex-direction: column;
}

h1, h2, h3, h4 {
    font-family: 'Josefin Slab', arial, sans-serif;
    font-weight: 700;
}

a {
    text-decoration: none;
    color: $baseDarker;
    &:hover {
        opacity: 0.8;
    }
}

.wrapper {
    max-width: $maxWidth;
    margin: 0rem auto;
    padding: $basePadding*2;
    flex: 1;
    margin-bottom: 4rem;
    display: grid;
    /*Define our columns */
    grid-template-columns: repeat(4, 1fr);
    @media screen and (max-width: $mobile) {
        grid-template-columns: repeat(1, 1fr); 
    }
    grid-column-gap: 2rem;
    &:not(:last-of-type){
        margin-bottom: $baseMargin*8;
    }
    .one {
        grid-column: span 1;
    }
    .two { 
        grid-column: span 2;
    }
    .three {
        grid-column: span 3;
    }
    .four {
        grid-column: span 4;
    }
    // eliminate spacing in form
    form & {
        padding: 0 !important;
        margin: 0 !important;
    }
}

h1 {
    font-size: 2.25rem;
    line-height: 2.75rem;
    margin-bottom: $baseMargin;
}

h2 {
    font-size: 2rem;
    line-height: 2.5rem;
    margin-bottom: $baseMargin;
}

h3 {
    font-size: 1.75rem;
    line-height: 2.25rem;
    margin-bottom: $baseMargin;
}

p, ul {
    font-size: 1.25rem;
}

small{
    display: block;
    font-size: 0.75rem;
}

.has-strikethrough {
    text-decoration: line-through;
}

.has-font-bold {
    font-weight: bold;
}

.iframe-container {
    position: relative;
    overflow: hidden;
    padding-top: 56.25%;
    iframe {
        position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          border: 0;
      }
}

.has-max-width {
    max-width: 100%;
}

.has-width-full {
    width: 100%;
}

.has-margin-none {
    margin: 0;
}

.cal-header{
    background: $baseRevert;
    color: $baseColor;
    margin:0;
    padding: 0.5rem 1rem;
    font-weight: bold;
}

.has-padding-none {
    padding: 0;
}

.has-padding-small {
    padding: 1rem;
}

.has-padding-left-base {
    padding-left: 1rem;
}

.has-margin-top-small{
    margin-top: 1rem;
}

.has-margin-top-medium {
    margin-top: 2rem;
}

.has-margin-bottom-none {
    margin-bottom: 0;
}

.is-relative {
    position: relative;
}

.is-large-text {
    font-size: 1.25rem;
}

.is-hidden {
    display: none;
}

.is-hidden-mobile {
    @media screen and (max-width: $mobile) {
        display: none;
    }
}

.visually-hidden {
    position: absolute !important;
    height: 1px; 
    width: 1px;
    overflow: hidden;
    clip: rect(1px 1px 1px 1px); /* IE6, IE7 */
    clip: rect(1px, 1px, 1px, 1px);
    white-space: nowrap; /* added line */
}

.has-background-color {
    background: $baseColor;
    color: $baseRevert;
    a {
        color: $baseRevert;
        font-weight: bold;
    }
}

.has-background-light {
    background: $baseLight;
}

.has-border-bottom {
    border-bottom: 3px solid;
}

.has-border {
    border: 3px solid;   
}

$rectSize: 1rem;

.has-border-30, .has-border-30:before {
    border-radius: $rectSize;
}

.rect-blue:before {
    position: absolute;
    background: $baseColor;
    width: 96%;
    height: 100%;
    content: '';
    z-index: -1;
}

.is-scewed, .is-scewed:before {
    -webkit-transform: skewY(3.4deg);
    transform: skewY(3.4deg);
}

.is-left:before {
    left: -$rectSize;
}

.is-right:before {
    right: -$rectSize;
}

.is-top:before {
    top: $rectSize;
}

.is-bottom:before {
    bottom: -$rectSize;
}
