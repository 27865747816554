footer {
    background: $baseColor;
    color: $baseRevert;
    padding: $basePadding;
    div {
        max-width: $maxWidth;
        margin: 0 auto;
        list-style: none;
        padding: 0;
    }
}